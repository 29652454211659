
import {
  Component,
  Prop,
  Watch,
  Mixins,
} from 'vue-property-decorator';
import Select from '@/components/global/inputs/select/Select.vue';
import { Instructor } from '@/modules/instructors/types';
import { InstructorsService } from '@/modules/instructors/services';
import { AttractionsService } from '@/modules/attractions/services';
import { instructors_store, global_store } from '@/store/';
import { ErrorHandlereMixin } from '@/mixins';
import { Statistics } from '../types';

const instructorsService = new InstructorsService();
const attractionsService = new AttractionsService();

@Component({
  components: {
    Select,
  },
})
export default class AttractionSharedStatisticCard extends Mixins(ErrorHandlereMixin) {
  @Prop({ type: Object })
  item!: Statistics.AttractionStatistic;

  @Prop({ type: Number })
  index!: number;

  selected_instructors = [] as unknown as Instructor[];

  async beforeMount() {
    this.get_instructors_of_attraction();
  }

  @Watch('date')
  update_statistics() {
    this.get_instructors_of_attraction();
  }

  get date() {
    return global_store.state.date;
  }

  get instructors() {
    return instructors_store.state.instructors;
  }

  async get_instructors_of_attraction() {
    try {
      this.selected_instructors = await instructorsService.get_instructors({
        worktableId: Number(this.$route.params.id),
        attractionIds: [this.item.id],
        date: global_store.state.date,
      });
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async connect_instructors_to_attraction(instructors: Instructor[]) {
    try {
      await attractionsService.connect_instructors(instructors, this.item.id, this.date);
    } catch (error) {
      this.handleServerError(error);
    }
  }

  get color() {
    switch (this.index) {
      case 0: return '#00A3E0';
      case 1: return '#FC4C02';
      case 2: return '#0047BB';
      case 3: return '#DA1984';
      case 4: return '#FC4C02';
      case 5: return '#0047BB';
      default: return '#00A3E0';
    }
  }
}

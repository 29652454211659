
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  PageTitle,
  Slider,
  Table,
  Avatar,
} from '@/components';
import { global_store, statistics_store, instructors_store } from '@/store';
import { Instructor } from '@/modules/instructors/types';
import { ErrorHandlereMixin } from '@/mixins';
import AttractionStatisticCard from '../components/AttractionStatisticCard.vue';
import AttractionStatisticsIssuedTickets from '../components/AttractionStatisticsIssuedTickets.vue';

const { actions, state } = statistics_store;

@Component({
  components: {
    AttractionStatisticsIssuedTickets,
    PageTitle,
    Slider,
    AttractionStatisticCard,
    Table,
    Avatar,
  },
})
export default class StatisticsDetail extends Mixins(ErrorHandlereMixin) {
  beforeMount() {
    this.get_statistics();
    this.get_instructors();
  }

  @Watch('date')
  update_statistics() {
    this.get_statistics();
    this.get_instructors();
  }

  instructors = [] as unknown as Instructor[];

  get date() {
    return global_store.state.date;
  }

  get dashboard() {
    return state.dashboard;
  }

  get history() {
    return state.history.history;
  }

  get creators() {
    return state.history.creators;
  }

  get total() {
    return state.history.total;
  }

  async get_instructors() {
    try {
      await instructors_store.actions.get_instructors({
        worktableId: Number(this.$route.params.id),
      });
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async get_statistics() {
    try {
      await actions.get_statistics({
        worktableId: Number(this.$route.params.id),
        date: this.date,
      });
    } catch (error) {
      this.handleServerError(error);
    }
  }
}


import { Component, Mixins } from 'vue-property-decorator';
import { attractions_store, auth_store } from '@/store';
import {
  Table,
  PageTitle,
  SimpleDialog,
} from '@/components';
import { AttractionStatusesTranslate, AttractionTypesTranslate } from '@/enums';
import { ErrorHandlereMixin } from '@/mixins';
import { Attractions } from '../types';

const { state, actions } = attractions_store;

@Component({
  components: {
    Table,
    PageTitle,
    SimpleDialog,
  },
})
export default class AttractionsTable extends Mixins(ErrorHandlereMixin) {
  created() {
    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('AttractionCreatedEvent', async (
      data: {
        socket: unknown,
        model: Attractions.Attraction
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('AttractionCreatedEvent - Attractions.vue', model);
        attractions_store.mutations.ADD_ATTRACTION(model);
      }
    });

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('AttractionUpdatedEvent', (
      data: {
        socket: unknown;
        model: Attractions.Attraction
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('AttractionUpdatedEvent - список аттракционов на рабочем столе', model);
        attractions_store.mutations.UPDATE_ATTRACTION(model);
      }
    });

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('AttractionDeletedEvent', (
      data: {
        socket: unknown;
        model: Attractions.Attraction
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('AttractionDeletedEvent - Attractions.vue', model);
        attractions_store.mutations.DELETE_ATTRACTION(model);
      }
    });
  }

  async mounted() {
    try {
      await actions.get_attractions();
      this.loading = false;
    } catch (error) {
      this.handleServerError(error);
    }
  }

  get user_id() {
    return auth_store.state.user.id;
  }

  get attractions() {
    return state.attractions;
  }

  set attractions(attractions: Attractions.Attraction[]) {
    this.attractions = attractions;
  }

  loading = true;

  is_delete_dialog = false;

  is_success_delete = false;

  attraction_on_delete: Attractions.Attraction | null = null;

  cols = [
    {
      text: '',
      value: 'draggle',
      sortable: false,
    },
    {
      text: 'Название',
      value: 'title',
    },
    {
      text: 'Статус',
      value: 'status',
    },
    {
      text: 'Рабочие столы',
      value: 'worktables',
      sortable: false,
    },
    {
      text: 'Тип',
      value: 'type',
    },
    {
      text: 'Вес',
      value: 'weight',
      sortable: false,
    },
    {
      text: 'Возраст (min)',
      value: 'min_age_of_one',
    },
    {
      text: 'Человек в слоте',
      value: 'seats',
    },
    {
      text: 'Префикс',
      value: 'prefix',
    },
    {
      text: 'Действие',
      value: 'actions',
      sortable: false,
    },
  ];

  attractions_status_translate(val: keyof typeof AttractionStatusesTranslate) {
    return AttractionStatusesTranslate[val];
  }

  attraction_type_translate(val: keyof typeof AttractionTypesTranslate) {
    return AttractionTypesTranslate[val];
  }

  item_edit_route(item: Attractions.Attraction) {
    this.$router.push({ name: Attractions.Pages.EDIT_ATTRACTION, params: { id: String(item.id) } });
  }

  add_route() {
    this.$router.push({ name: Attractions.Pages.ATTRACTION_NEW });
  }

  async delete_attraction() {
    try {
      if (this.attraction_on_delete) {
        await actions.delete_attraction(this.attraction_on_delete);

        this.attraction_on_delete = null;
        this.is_delete_dialog = false;
        this.is_success_delete = true;
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
